<template>
  <div class="container">
    <md-card class="dash-content">
      <div class="header-wrapper">
        <PageHeader title="Manage Professionals" />
        <div class="loader">
          <Wave
            v-if="loading || updating || deleting"
            width="50px"
            color="#d9534f"
          />
        </div>
      </div>
      <md-table
        v-model="professionals.mdData"
        md-card
        md-sort="title"
        md-sort-order="asc"
      >
        <md-table-toolbar class="mb-4">
          <div class="md-toolbar-section-start">
            <md-button
              class="md-primary button-icon"
              :disabled="exporting"
              @click="exportExcel"
            >
              Download Excel
              <img src="@/assets/svg/excel.svg" />
            </md-button>
          </div>
          <div class="toolbar-filter-wrapper">
            <md-field md-clearable class="md-toolbar-section-end">
              <md-input placeholder="Search by name..." v-model="search" />
            </md-field>
            <md-menu md-size="medium" md-align-trigger>
              <md-button
                md-menu-trigger
                class="md-primary button-icon"
                disabled
              >
                Filter
                <img src="@/assets/svg/filter.svg" />
              </md-button>

              <md-menu-content>
                <md-menu-item>Approved</md-menu-item>
                <md-menu-item>Pending</md-menu-item>
              </md-menu-content>
            </md-menu>
          </div>
        </md-table-toolbar>
        <md-table-row
          slot="md-table-row"
          slot-scope="{ item }"
          :class="{ 'table-highlight': item.promote_count > 0 }"
        >
          <md-table-cell md-label="S/N" md-numeric>{{ item.id }}</md-table-cell>
          <md-table-cell md-label="Name">
            {{ item.user ? item.user.name : "--" }}
          </md-table-cell>
          <md-table-cell md-label="Email">{{
            item.user ? item.user.email : "--"
          }}</md-table-cell>
          <md-table-cell md-label="Phone number"
            >{{ item.phone_ext }}{{ item.phone }}</md-table-cell
          >
          <md-table-cell md-label="Company">{{
            item.company_name
          }}</md-table-cell>

          <md-table-cell md-label="Registered By">
            <div class="badge yellow">
              {{
                item.register
                  ? item.register.user.first_name +
                    " " +
                    item.register.user.last_name
                  : "User"
              }}
            </div>
          </md-table-cell>
          <!-- <md-table-cell md-label="code">{{
            item.tracking_code
          }}</md-table-cell> -->
          <md-table-cell md-label="Status">
            <div
              class="badge"
              :class="{
                success: item.approval_status == 'approved',
                danger: item.approval_status == 'disapproved',
              }"
            >
              {{ item.approval_status }}
            </div></md-table-cell
          >
          <md-table-cell md-label="Actions">
            <md-menu md-size="medium" md-align-trigger>
              <md-button :disabled="updating" md-menu-trigger class="bggreen"
                >Menu</md-button
              >

              <md-menu-content>
                <md-menu-item
                  v-if="item.promote_count == 0"
                  @click="promote(item)"
                  >Promote <i class="material-icons">arrow_upward</i>
                </md-menu-item>
                <md-menu-item v-else @click="promote(item, 'update')"
                  >Update Promotion
                </md-menu-item>
                <md-menu-item @click="setProfessional(item)"
                  >Preview</md-menu-item
                >
                <div v-if="hasPermission">
                  <md-menu-item
                    v-if="
                      ['disapproved', 'pending'].includes(item.approval_status)
                    "
                    @click="changeProfessionalStatus(item.id, 'approved')"
                    >Approve</md-menu-item
                  >
                  <md-menu-item
                    v-if="
                      ['approved', 'pending'].includes(item.approval_status)
                    "
                    @click="changeProfessionalStatus(item.id, 'disapproved')"
                    >Disapprove</md-menu-item
                  >
                  <md-menu-item @click="setProfessional(item, 'services')"
                    >Services</md-menu-item
                  >
                  <md-menu-item>Delete </md-menu-item>
                </div>
              </md-menu-content>
            </md-menu>
          </md-table-cell>
        </md-table-row>
      </md-table>
      <span v-if="loading" class="b-loading">Loading...</span>
      <pagination
        v-model="professionals.mdPage"
        :records="professionals.mdCount"
        :per-page="professionals.mdPerPage"
        @paginate="updatePagination"
      />
    </md-card>

    <md-dialog :md-active.sync="show">
      <md-dialog-title>Professional Details</md-dialog-title>
      <div class="cancel-modal">
        <i class="material-icons" @click="show = false">close</i>
      </div>

      <div class="modal-contain" v-if="professional">
        <div v-if="professional.user">
          <div class="item">
            <strong>Company name</strong>
            <span> {{ professional.company_name }}</span>
          </div>
          <div class="item">
            <strong>About</strong>
            <span v-html="professional.about"> </span>
          </div>

          <div class="item">
            <strong>Services</strong>
            <span
              class="badge"
              v-for="service in professional.services"
              :key="service.id"
            >
              {{ service.title }}
            </span>
          </div>
          <div class="item">
            <strong>Rating</strong>
            <span> {{ professional.rating }}</span>
          </div>

          <div class="item">
            <strong>Phone Number</strong>
            <a :href="`tel:${professional.phone_ext + professional.phone}`">
              {{ professional.phone_ext + professional.phone }}</a
            >
          </div>

          <div class="item">
            <strong>Date Created</strong>
            <span> {{ professional.created_at | formatDate }}</span>
          </div>

          <div class="item">
            <strong>Approval Status</strong>
            <div
              class="badge"
              :class="{
                success: professional.approval_status == 'approved',
                danger: professional.approval_status == 'disapproved',
              }"
            >
              {{ professional.approval_status }}
            </div>
          </div>

          <div class="item">
            <strong>Tracking code</strong>
            <span> {{ professional.tracking_code }}</span>
          </div>

          <div class="item">
            <strong>Logo</strong>
          </div>
          <div class="media">
            <div class="logo">
              <img
                :src="getStorageUrl(professional.logo)"
                onerror="this.src='https://via.placeholder.com/220x150?text=No%20Image'"
                alt="Display Image"
              />
            </div>
          </div>

          <div class="item">
            <strong>Tin</strong>
            <span> {{ professional.tin }}</span>
          </div>

          <div class="media">
            <div class="doc-display">
              <img
                :src="professional.cac_reg_upload"
                onerror="this.src='https://via.placeholder.com/220x150?text=No%20Image'"
                alt="Display Image"
              />
            </div>
          </div>

          <hr />
          <div>
            <h4>Socials information</h4>
          </div>

          <div class="item">
            <strong>Facebook</strong>
            <span> {{ professional.facebook_link }}</span>
          </div>

          <div class="item">
            <strong>Linkedin</strong>
            <span> {{ professional.linkedin_link }}</span>
          </div>
          <div class="item">
            <strong>Instagram</strong>
            <span> {{ professional.instagram_link }}</span>
          </div>

          <hr />
          <div>
            <h4>User information</h4>
          </div>
          <div class="item">
            <strong>Fullname</strong>
            <span> {{ professional.user.name }}</span>
          </div>

          <div class="item">
            <strong>Email Address</strong>
            <span> {{ professional.user.email }}</span>
          </div>

          <div class="item">
            <strong>Phone Number</strong>
            <a
              :href="`tel:${
                professional.user.phone_ext + professional.user.phone
              }`"
            >
              {{ professional.user.phone_ext + professional.user.phone }}</a
            >
          </div>
        </div>
        <div v-else>
          <div class="alert alert-info">User Object not found</div>
        </div>
      </div>
    </md-dialog>

    <md-dialog :md-active.sync="showS">
      <md-dialog-title>Professional Services</md-dialog-title>
      <div class="cancel-modal">
        <i class="material-icons" @click="showS = false">close</i>
      </div>

      <div class="modal-contain" v-if="professional">
        <div class="s-con" v-if="professional.services.length">
          <div class="head">
            <div>
              <md-checkbox v-model="checkAllServices" @change="toggleServices">
              </md-checkbox>
            </div>
            <div>Title</div>
            <div>Status</div>
          </div>
          <div class="body">
            <div
              class="s-items"
              v-for="service in professional.services"
              :key="service.id"
            >
              <div class="s-item">
                <md-checkbox v-model="multi_services" :value="service.pivot.id">
                </md-checkbox>
              </div>
              <div class="s-item">{{ service.title }}</div>
              <div class="s-item">{{ service.pivot.approval_status }}</div>
            </div>
          </div>

          <select
            class="form-control"
            name="approval_status"
            v-model="approval_status"
          >
            <option value="" disabled>Select Approval Status</option>
            <option value="approved">Approve</option>
            <option value="pending">Pending</option>
            <option value="disapproved">Disapproved</option>
          </select>
          <br />

          <mdc-button
            :disabled="!approval_status"
            :loading="updating"
            v-on:click.native="submitServices"
            >{{ "Update Service" }}</mdc-button
          >
        </div>
        <div v-else>
          <div class="alert alert-info">
            <span>Professional has no service</span>
          </div>
        </div>
      </div>
    </md-dialog>
  </div>
</template>

<script>
import { createNamespacedHelpers } from "vuex";
const { mapActions, mapGetters } = createNamespacedHelpers("professional");
export default {
  name: "Professional",
  data() {
    return {
      updating: false,
      deleting: false,
      exporting: false,
      search: "",
      show: false,
      professional: null,
      time: null,
      showS: false,
      multi_services: [],
      checkAllServices: false,
      approval_status: "",
    };
  },
  watch: {
    search(val) {
      this.waitForIt(val);
    },
    professional(val) {
      let data = [];
      this.multi_services = [];
      this.checkAllServices = false;

      val.services.map((service) => {
        if (service.pivot.approval_status == "approved") {
          data.push(service.pivot.id);
        }
      });

      this.multi_services = data;
    },
  },
  methods: {
    ...mapActions([
      "getProfessionals",
      "updateProfessionalStatus",
      "updateServiceStatus",
    ]),
    async updatePagination(page) {
      let data = { page };
      if (this.search) data.keyword = this.search;
      await this.getProfessionals(data);
    },
    async changeProfessionalStatus(id, status) {
      try {
        this.updating = true;
        await this.updateProfessionalStatus({ approval_status: status, id });
        this.updating = false;
        this.toast("success", "Professional Status Updated ");
      } catch (err) {
        this.updating = false;
        console.error(err);
      }
    },
    async submitServices() {
      this.updating = true;
      var data = new FormData();
      if (this.approval_status == "") return;
      data.append("approval_status", this.approval_status);
      data.append("services", this.multi_services);
      data.append("_method", "PATCH");

      await this.updateServiceStatus(data);
      this.updating = false;
      this.showS = false;
      this.toast("success", "Status updated");
    },
    async submitSearch() {
      await this.getProfessionals({ keyword: this.search });
    },
    async promote(item, mode = "create") {
      console.log(item, mode);
      this.$eventBus.$emit("promote", {
        item_type: this.promotions.PROFESSIONAL,
        item_id: item.id,
        title: item.company_name,
        promote: item.promote,
        mode,
      });
    },
    async exportExcel() {
      this.exporting = true;
      await this.$store.dispatch("auth/export", { model: "professional" });
      this.exporting = false;
    },
    setProfessional(professional, type = "professional") {
      if (type == "professional") {
        this.show = true;
      } else {
        this.showS = true;
      }
      this.professional = professional;
    },
    waitForIt(val) {
      if (this.time) {
        clearTimeout(this.time);
      }
      this.time = setTimeout(() => this.submitSearch(val), 1000);
    },
    toggleServices: function () {
      if (this.checkAllServices) {
        this.multi_services = [];
        this.multi_services = this.handyman.services.map(
          (service) => service.pivot.id
        );
      } else {
        this.multi_services = [];
      }
    },
  },
  created() {
    this.$eventBus.$on("promoted", (id) => {
      this.getProfessionals();
    });
  },
  mounted() {
    this.getProfessionals();
  },
  computed: {
    ...mapGetters(["professionals", "loading"]),
    hasPermission() {
      return this.$store.getters["auth/has_module_permission"]("crud");
    },
  },
};
</script>

<style lang="scss" scoped>
.md-table-cell {
  width: 0% !important;
  padding: 0rem 0.8rem;
}

.md-table + .md-table {
  margin-top: 16px;

  thead {
    height: 50px !important;
  }
}

.badge {
  background: #f3f3f3;

  &.success {
    background: green;
  }

  &.danger {
    background: red;
  }
}

.s-con {
  .head,
  .body .s-items {
    display: flex;
    align-items: center;

    > div {
      width: calc(100% / 3);
    }
  }
  .head {
    font-weight: 700;
  }

  .body .s-items .s-item {
    padding: 10px 0px;
  }
}
</style>
